import styled from 'styled-components';

const MarketingProPageContainer = styled.div`
  .header-container {
    .website-header {
      &.shrink-nav.scroll-down {
        .website-header--main-menu {
          max-height: 0;
          overflow: hidden;
        }
      }

      &.mobile-menu-opened {
        .toolbox-sticky-subnav {
          display: none;
        }
      }
    }
  }
  main {
    ${({ promoHeight }) => {
      return `padding-top: ${promoHeight}px;`;
    }}
  }
`;

export default MarketingProPageContainer;
