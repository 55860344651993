import React, { useContext } from 'react';
import Bookmark from 'components/Page/Toolbox/Bookmark';
import HyperLink from 'components/Core/HyperLink';
import { useHeaderHeight } from 'hooks/useHeaderHeight';
import HeaderNavContext from 'utils/HeaderNavContext';
import rightArrow from 'images/right-arrow-gray.svg';
import rightArrowLight from 'images/right-arrow-lightgray.svg';
import forwardSlash from 'images/forward_slash.svg';
import forwardSlashWhite from 'images/forward_slash_white.svg';
import { Helmet } from 'react-helmet';
import { generateBreadcrumbSchema } from 'utils/seo';
import { Container, InnerContainer, ChildLink, ParentLink } from './styles';

const Breadcrumbs = ({
  breadcrumbs: initialBreadcrumbs,
  absoluteMode,
  stickyWithoutHeader,
  theme,
  layout,
  ignoreBookmark,
}) => {
  const breadcrumbSchema = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: generateBreadcrumbSchema(initialBreadcrumbs),
  };

  const darkTheme = theme === 'dark';
  const headerHeight = useHeaderHeight();
  const filteredBreadcrumbs = initialBreadcrumbs?.filter((x) => x !== false);
  const { showSimpleOrMain } = useContext(HeaderNavContext);

  const formatBreadcrumb = (breadcrumbs, breadcrumb, index, isMobileBreadcrumb) => {
    const singleParentPageMobile = breadcrumbs.length <= 1 && isMobileBreadcrumb;

    const hasSubPage =
      !singleParentPageMobile && breadcrumbs.length > index + 1 && index !== breadcrumbs.length - 1;

    if (breadcrumb.url) {
      return (
        <HyperLink href={breadcrumb.url} key={`breadcrumb-${index}`}>
          <ParentLink darkTheme={darkTheme}>
            {singleParentPageMobile && (
              <img
                src={darkTheme ? rightArrow : rightArrowLight}
                className="breadcrumb--arrow reverse"
                alt="breadcrumb-arrow"
              />
            )}
            {breadcrumb.title}
            {breadcrumb.icon && <img src={breadcrumb.icon} className="breadcrumb--icon" />}
            {hasSubPage && (
              <img
                src={darkTheme ? forwardSlash : forwardSlashWhite}
                className="breadcrumb--arrow"
                alt="breadcrumb-arrow"
              />
            )}
          </ParentLink>
        </HyperLink>
      );
    }

    return (
      <ChildLink key={`breadcrumb-${index}`} darkTheme={darkTheme}>
        {breadcrumb.title}
        {breadcrumb.icon && <img src={breadcrumb.icon} className="breadcrumb--icon" />}
      </ChildLink>
    );
  };

  const MobileBreadcrumbs = () => {
    const mobileBreadCrumbs = filteredBreadcrumbs.filter((x) => !x.hideInMobile);
    const mobileBreadCrumbLength = mobileBreadCrumbs.length - 1;
    return (
      <div className="max-sm" style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ margin: '13px 0px 5px', display: 'flex', flexDirection: 'row' }}>
          {mobileBreadCrumbs.map((crumb, index) => {
            const hasNextCrumb =
              index !== mobileBreadCrumbLength ||
              mobileBreadCrumbs.length === 1 ||
              mobileBreadCrumbs[mobileBreadCrumbLength].url;
            return hasNextCrumb && formatBreadcrumb(mobileBreadCrumbs, crumb, index, true);
          })}
        </div>
        {!mobileBreadCrumbs[mobileBreadCrumbLength].url && (
          <div>
            {formatBreadcrumb(
              mobileBreadCrumbs,
              mobileBreadCrumbs[mobileBreadCrumbLength],
              0,
              true,
            )}
          </div>
        )}
      </div>
    );
  };

  const getDesktopBreadcrumbs = () => (
    <div className="min-sm" style={{ display: 'flex', flexDirection: 'row' }}>
      {filteredBreadcrumbs.map((crumb, index) => {
        return formatBreadcrumb(filteredBreadcrumbs, crumb, index, false);
      })}
    </div>
  );

  return (
    <>
      {breadcrumbSchema && (
        <Helmet>
          <script type="application/ld+raw">{JSON.stringify(breadcrumbSchema)}</script>
        </Helmet>
      )}
      <Container
        className="breadcrumb--wrapper"
        absoluteMode={absoluteMode}
        simpleHeader={showSimpleOrMain}
        stickyWithoutHeader={stickyWithoutHeader}
        headerHeight={headerHeight}
        layout={layout}
      >
        {filteredBreadcrumbs && (
          <InnerContainer layout={layout} className="breadcrumb--inner-container">
            {getDesktopBreadcrumbs()}
            <MobileBreadcrumbs />
            {!ignoreBookmark && layout === 'toolbox' && (
              <Bookmark
                label="Bookmark"
                subType="breadcrumb"
                color={theme === 'dark' ? 'var(--grey-8)' : 'var(--white)'}
              />
            )}
          </InnerContainer>
        )}
      </Container>
    </>
  );
};

export default Breadcrumbs;
